// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  signOut
} from 'firebase/auth';

// import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCYqNcgSf9CVHWqNUTrGwPOyL19q7Goc7w',
  authDomain: 'edurupt-app.firebaseapp.com',
  projectId: 'edurupt-app',
  storageBucket: 'edurupt-app.appspot.com',
  messagingSenderId: '175691104985',
  appId: '1:175691104985:web:1b88cffac1adc80e23b4c8',
  measurementId: 'G-59F526NP89'
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');

appleProvider.addScope('email');
appleProvider.addScope('name');

const db = getFirestore(firebaseApp);

const auth = getAuth();

const signInWithGoogle = () => {
  return signInWithPopup(auth, provider);
};

const signOutFromApp = () => {
  return signOut(auth);
};

const signInWithApple = () => {
  return signInWithPopup(auth, appleProvider);
};

const getToken = () => {
  return auth?.currentUser?.getIdTokenResult();
};

export { firebaseApp, auth, db, signInWithGoogle, signInWithApple, signOutFromApp, getToken };
