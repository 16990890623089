const initialState = {
  loading: false,
  id: null,
  content: null,
  type: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'selectLesson':
      return {
        ...state,
        ...action.details,
        id: action.id,
        content: action.content
      };

    case 'UPDATE_LESSON': {
      return {
        ...state,
        content: action.content,
        type: action.contentType
      };
    }
    case 'REMOVE_CONTENT': {
      return {
        ...state,
        content: null
      };
    }

    case 'clearLesson': {
      return {
        ...state,
        ...initialState
      };
    }
    default:
      return state;
  }
};

export default reducer;
