import React from 'react';

import { HStack, Heading } from '@chakra-ui/react';

export default function NavHeader() {
  return (
    <HStack spacing={2} align="center" justify={'center'} bg="brand.primary" pt={3} pb={3}>
      <Heading fontSize={24} fontWeight={700} color="essentials.white">
        Edurupt
      </Heading>
    </HStack>
  );
}
