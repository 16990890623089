import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import PageLoader from 'components/PageLoader';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from 'services/firebase';
import logout from 'actions/auth/logout';
import { signOutFromApp } from 'services/firebase';

function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.auth.token);
  const [loading, setLoading] = useState(true);

  let location = useLocation();

  const LogOut = () => {
    try {
      dispatch(
        logout({
          navigate
        })
      );
      signOutFromApp();
    } catch (error) {
      console.warn('Error occured in logout!');
    }
  };

  useEffect(() => {
    if (!token) {
      dispatch({ type: 'setNextPath', path: location });
    } else {
      onAuthStateChanged(auth, (user) => {
        user &&
          user.getIdToken().then(function (refreshToken) {
            if (refreshToken) {
              localStorage.setItem('edu_wa_session_token', refreshToken);
              setLoading(false);
            } else {
              LogOut();
            }
          });
      });
    }
  }, []);

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (loading) return <PageLoader />;

  return children;
}

AuthProvider.propTypes = {
  children: PropTypes.any
};

export default AuthProvider;
