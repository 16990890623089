import { combineReducers } from 'redux';
import auth from './auth';
import courses from './courses';
import course from './course';
import lesson from './lesson';
import userQuizSubmission from './userQuizSubmission';
import chatRooms from './chatRooms';
import messages from './messages';
import aiMessages from './aiMessages';

export default combineReducers({
  auth,
  courses,
  course,
  lesson,
  userQuizSubmission,
  chatRooms,
  messages,
  aiMessages
});
