import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys
);

const pill = definePartsStyle({
  field: {
    background: 'essentials.white',
    borderRadius: 'brandSmall',
    boxShadow: '0px 0px 5px 5px rgba(0, 0, 0, 0.02)',
    color: 'grey.800',
    border: '1px solid',
    borderColor: 'gray.300',

    _dark: {
      borderColor: 'gray.600',
      background: 'gray.800',
      color: 'grey.800',
      _focus: {
        borderColor: 'schemeAccent.500'
      }
    },
    _focus: {
      borderColor: 'schemeAccent.500',
      color: 'essentials.black'
    }
  },
  element: {
    color: 'grey.300',
    _dark: {}
  }
});

const chatInput = definePartsStyle({
  field: {
    border: '1px solid',
    borderColor: 'grey.300',
    background: 'essentials.white',
    borderRadius: '16px',
    color: 'grey.200',
    _dark: {
      border: '1px solid',
      borderColor: 'gray.600',
      background: 'gray.800',
      color: 'grey.300',
      _focus: {
        border: '1px solid',
        borderColor: 'schemeAccent.500'
      }
    },
    _focus: {
      border: '1px solid',
      borderColor: 'schemeAccent.500',
      color: 'essentials.black'
    }
  }
});

const chatAIInput = definePartsStyle({
  field: {
    border: '1px solid',
    borderColor: 'grey.300',
    background: 'essentials.white',
    borderRadius: '16px',
    color: 'grey.200',
    _dark: {
      border: '1px solid',
      borderColor: 'gray.600',
      background: 'gray.800',
      color: 'grey.300',
      _focus: {
        border: '1px solid',
        borderColor: '#996FF0'
      }
    },
    _focus: {
      border: '1px solid',
      borderColor: '#996FF0',
      color: 'essentials.black'
    }
  }
});

const inputTheme = defineMultiStyleConfig({
  variants: { pill, chatInput, chatAIInput }
});

export default inputTheme;
