import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import DashboardLayout from 'layout/DashboardLayout';

import { publicRoutes } from './routes';
import AuthProvider from './components/AuthProvider';
import PageLoader from 'components/PageLoader';

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<PageLoader />}>
        <Routes>
          {publicRoutes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            );
          })}

          <Route
            path="*"
            name="Home"
            element={
              <AuthProvider>
                <DashboardLayout />
              </AuthProvider>
            }></Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
