import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import logger from 'redux-logger';
import AppConfig from './AppConfig';

const preloadedState = {};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    AppConfig.env == 'development' ? getDefaultMiddleware().concat(logger) : getDefaultMiddleware(),
  devTools: AppConfig.env == 'development',
  preloadedState
});

export default store;
