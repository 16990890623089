import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const brandPrimary = defineStyle({
  borderWidth: '0.5px',
  borderStyle: 'solid',
  borderColor: 'essentials.black',
  width: '100%',
  _dark: {
    borderColor: 'essentials.white'
  }
});

const divider = defineStyleConfig({
  variants: { brandPrimary }
});
export default divider;
