const initialState = {
  list: [],
  loading: false,
  assigned: [],
  selected: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'fetchCourses':
      return {
        ...state,
        list: action.courses || []
      };

    case 'listAssignedCourses':
      return {
        ...state,
        assigned: action.courses || []
      };
    case 'selectCourse':
      return {
        ...state,
        selected: action.course || null
      };
    case 'startCourseLoading':
      return {
        ...state,
        loading: true
      };
    case 'stopCourseLoading':
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};

export default reducer;
