const initialState = {
  lessons: [],
  loading: false,
  modules: [],
  details: null,
  Progresses: [],
  newLessons: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'startCourseInfoLoading':
      return {
        ...state,
        loading: true
      };
    case 'stopCourseInfoLoading':
      return {
        ...state,
        loading: false
      };

    case 'populateLessons':
      return {
        ...state,
        details: action.course,
        modules: action.modules,
        lessons: action.lessons
      };
    case 'updateLessonProgresses':
      return {
        ...state,
        Progresses: action.Progresses
      };

    case 'updateLessonProgress': {
      const index = state.Progresses.findIndex(
        (item) => item.section.toString() === action.section.toString()
      );
      let newArray = [...state.Progresses];
      if (index >= 0) {
        newArray[index] = { ...newArray[index], progress: action.progress };
      } else {
        newArray = [
          ...state.Progresses,
          { section: Number(action.section), progress: action.progress }
        ];
      }

      return {
        ...state,
        Progresses: newArray
      };
    }
    case 'ADD_NEW_LESSON': {
      let updateNewlessons = [...state.newLessons];
      updateNewlessons.push(action.newLesson);
      return {
        ...state,
        newLessons: updateNewlessons
      };
    }

    case 'deleteLesson': {
      return {
        ...state,
        lessons: state.lessons.filter((item) => item.id.toString() !== action.lessonId.toString())
      };
    }

    case 'deleteCreatedLesson': {
      return {
        ...state,
        newLessons: state.newLessons.filter(
          (item) => item.id.toString() !== action.lessonId.toString()
        )
      };
    }

    case 'clearSelectedCourse': {
      return {
        ...state,
        ...initialState
      };
    }

    default:
      return state;
  }
};

export default reducer;
