import { extendTheme } from '@chakra-ui/react';
// import { theme as base } from '@chakra-ui/react';
// ${base.fonts?.heading}

// Global style overrides
import styles from './styles';

// Component style overrides
import Button from './components/button';
import Card from './components/card';
import Input from './components/input';
import Textarea from './components/textArea';
import Divider from './components/divider';
import Tabs from './components/tabs';
import Checkbox from './components/checkbox';
import Radio from './components/radio';

const overrides = {
  styles,
  fonts: {
    body: `Inter, sans-serif`,
    heading: `Outfit, sans-serif`
  },
  colors: {
    brand: {
      primary: '#072058',
      accent: '#00a1e2',
      light: '#79A2FF',
      bg: '#F9F9F9'
    },
    essentials: {
      white: '#ffffff',
      black: '#000000',
      red: '#E41C1C'
    },
    grey: {
      10: '#F9F9F9',
      50: '#efefef',
      100: '#d6d6d6',
      200: '#bbbbbb',
      300: '#a0a0a0',
      400: '#8b8b8b',
      500: '#777777',
      600: '#6f6f6f',
      700: '#6f6f6f',
      800: '#5a5a5a',
      900: '#474747'
    },
    schemePrimary: {
      100: '#072058',
      200: '#072058',
      500: '#072058'
    },
    schemeAccent: {
      100: '#00a1e2',
      200: '#00a1e2',
      500: '#00a1e2'
    },
    schemeLight: {
      200: '#79A2FF',
      500: '#79A2FF'
    }
  },
  radii: {
    none: '0',
    default: '20px',
    brandSmall: '12px',
    full: '999px'
  },
  components: {
    Button,
    Card,
    Input,
    Textarea,
    Divider,
    Tabs,
    Checkbox,
    Radio
  }
};

export default extendTheme(overrides);
