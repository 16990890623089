import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  cardAnatomy.keys
);

const variants = {
  courseCard: definePartsStyle({
    container: {
      background: 'essentials.white',
      boxShadow: '0px 0px 5px 5px rgba(0, 0, 0, 0.02)',
      borderRadius: 'default',
      p: '4',
      mb: '4',
      cursor: 'pointer',
      _dark: {
        background: 'grey.800'
      }
    },
    body: {
      p: '0'
    }
  })
};
const Card = defineMultiStyleConfig({ variants });

export default Card;
