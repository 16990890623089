import React from 'react';

// Public Routes
const Login = React.lazy(() => import('./views/LoginPage'));
const Page404 = React.lazy(() => import('./views/404'));
const Page500 = React.lazy(() => import('./views/500'));

const publicRoutes = [
  { path: '/login', exact: true, name: 'Login Page', element: Login },
  { path: '/404', exact: true, name: '404 Page', element: Page404 },
  { path: '/500', exact: true, name: '500 Page', element: Page500 }
];

// Protected Routes
const Dashboard = React.lazy(() => import('./views/Home'));
const Courses = React.lazy(() => import('./views/Courses'));
const CourseDetails = React.lazy(() => import('./views/CourseDetails'));
const MessagesPage = React.lazy(() => import('./views/Messages'));
const Conversations = React.lazy(() => import('./views/Conversations'));
const AIChatRoom = React.lazy(() => import('./views/AIChat/ChatRoomPage'));
const ProfilePage = React.lazy(() => import('./views/Profile'));
const ProfilePages = React.lazy(() => import('./views/ProfilePages'));
const CoursePlayer = React.lazy(() => import('./views/CoursePlayer'));

const EditCourse = React.lazy(() => import('./views/EditCourse'));
const CourseEnrol = React.lazy(() => import('./views/CourseEnrol'));

// const UserQuizzesView = React.lazy(() => import('./views/UserQuizzes'));
const UserTakeQuizView = React.lazy(() => import('./views/UserQuizzes/TakeQuiz'));
const UserQuizResultView = React.lazy(() => import('./views/UserQuizzes/QuizResult'));

//Teacher Dashboard Routes

const TeacherDashboard = React.lazy(() => import('./views/TeacherDashboard'));
const TeacherDashboardBatches = React.lazy(() => import('./views/TeacherDashboard/Batches'));
const TeacherDashboardStudents = React.lazy(() => import('./views/TeacherDashboard/Students'));
const StudentCourseProgress = React.lazy(() =>
  import('./views/TeacherDashboard/StudentCourseProgress')
);
const TeacherDashboardQuizResults = React.lazy(() =>
  import('./views/TeacherDashboard/QuizAttempts')
);

//Admin Dashboard Routes

const AdminDashboard = React.lazy(() => import('./views/AdminDashboard'));
const AdminDashboardBatches = React.lazy(() => import('./views/AdminDashboard/Batches'));
const AdminDashboardBatchStudents = React.lazy(() =>
  import('./views/AdminDashboard/BatchStudents')
);
const AdminDashboardStudentCourseProgress = React.lazy(() =>
  import('./views/AdminDashboard/StudentCourseProgress')
);

const AdminDashboardEnrolments = React.lazy(() => import('./views/AdminDashboard/Enrolments'));

const AdminDashboardCourses = React.lazy(() => import('./views/AdminDashboard/Courses'));

const AdminDashboardAddAceess = React.lazy(() => import('./views/AdminDashboard/AddAccess'));

const AdminDashboardQuizzes = React.lazy(() => import('./views/AdminDashboard/Quizzes'));

const AdminDashboardQuizResults = React.lazy(() => import('./views/AdminDashboard/QuizAttempts'));

const QuizEditView = React.lazy(() => import('./views/AdminDashboard/Quizzes/EditQuiz'));

const QuizCreateView = React.lazy(() => import('./views/AdminDashboard/Quizzes/Create'));

const DashBoardRoutes = [
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/courses', name: 'Courses', element: Courses },
  { path: '/course-details/:course', name: 'Course Details', element: CourseDetails },
  { path: '/course-enrol/:course', name: 'Course Enrol', element: CourseEnrol },
  { path: '/messages', name: 'Messages', element: MessagesPage },
  { path: '/conversations', name: 'Conversations', element: Conversations },
  { path: '/ai-chat-room', name: 'AIChatRoom', element: AIChatRoom },
  // { path: '/user-quizzes', name: 'UserQuizzesView', element: UserQuizzesView },
  { path: '/take-quiz', name: 'UserTakeQuiz', element: UserTakeQuizView },
  { path: '/quiz-result', name: 'UserQuizResult', element: UserQuizResultView },

  { path: '/profile', name: 'Profile', element: ProfilePage },
  { path: '/profile-pages', name: 'Profile Pages', element: ProfilePages },
  { path: '/course-player/:course', name: 'Course Player', element: CoursePlayer },
  {
    path: '/edit-course/:course',
    name: 'Edit Course',
    element: EditCourse,
    canAcess: ['teacher', 'admin']
  },

  {
    path: '/teacher-dashboard',
    name: 'Teacher Dashboard',
    element: TeacherDashboard,
    canAcess: ['teacher'],
    childRoutes: [
      {
        path: '/teacher-dashboard/batches',
        name: 'Teacher Dashboard Batches',
        element: TeacherDashboardBatches,
        canAcess: ['teacher']
      },
      {
        path: '/teacher-dashboard/batch-students',
        name: 'Teacher Dashboard Students',
        element: TeacherDashboardStudents,
        canAcess: ['teacher']
      },
      {
        path: '/teacher-dashboard/batch-students/:enrollment',
        name: 'Teacher Dashboard Student Progress',
        element: StudentCourseProgress,
        canAcess: ['teacher']
      },
      {
        path: '/teacher-dashboard/quiz-results',
        name: 'Teacher Dashboard Quiz Results',
        element: TeacherDashboardQuizResults,
        canAcess: ['teacher']
      }
    ]
  },
  {
    path: '/admin-dashboard',
    name: 'Admin Dashboard',
    element: AdminDashboard,
    canAcess: ['admin'],
    childRoutes: [
      {
        path: '/admin-dashboard/batches',
        name: 'Admin Dashboard Batches',
        element: AdminDashboardBatches,
        canAcess: ['admin']
      },
      {
        path: '/admin-dashboard/batch-students',
        name: 'Admin Dashboard Batch Students',
        element: AdminDashboardBatchStudents,
        canAcess: ['admin']
      },
      {
        path: '/admin-dashboard/batch-students/:enrollment',
        name: 'Admin Dashboard Student Progress',
        element: AdminDashboardStudentCourseProgress,
        canAcess: ['admin']
      },
      {
        path: '/admin-dashboard/enrolments',
        name: 'Admin Dashboard Enrolments',
        element: AdminDashboardEnrolments,
        canAcess: ['admin']
      },
      {
        path: '/admin-dashboard/course-list',
        name: 'Admin Dashboard Courses',
        element: AdminDashboardCourses,
        canAcess: ['admin']
      },
      {
        path: '/admin-dashboard/add-access',
        name: 'Admin Dashboard Add Acess',
        element: AdminDashboardAddAceess,
        canAcess: ['admin']
      },
      {
        path: '/admin-dashboard/quizess',
        name: 'Admin Dashboard Quizzes',
        element: AdminDashboardQuizzes,
        canAcess: ['admin']
      },
      {
        path: '/admin-dashboard/quiz-results',
        name: 'Admin Dashboard Quiz Results',
        element: AdminDashboardQuizResults,
        canAcess: ['admin']
      }
    ]
  },
  {
    path: '/quizess/:quizId',
    name: 'Quiz Edit View',
    element: QuizEditView,
    canAcess: ['admin']
  },
  {
    path: '/create-quiz',
    name: 'Quiz Create View',
    element: QuizCreateView,
    canAcess: ['admin']
  }
];

export { DashBoardRoutes, publicRoutes };
