const initialState = {
  mountingState: 'none',
  previousMessages: [],
  allMessages: [],
  dailyCredit: 10
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_AI_MOUNT_STATE':
      return {
        ...state,
        mountingState: action.mountingState
      };

    case 'LOAD_AI_PREVIOUS_MESSAGES':
      return {
        ...state,
        previousMessages: action.previousMessages
      };

    case 'LOAD_AI_ALL_MESSAGES':
      return {
        ...state,
        allMessages: action.allMessages,
        dailyCredit: action.dailyCredit
      };

    case 'CLEAR_AI_CHAT_MESSAGES_STATE':
      return initialState;

    default:
      return state;
  }
};

export default reducer;
