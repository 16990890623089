import React from 'react';
import PropTypes from 'prop-types';

import { Box, Text, VStack, HStack } from '@chakra-ui/react';

import { Link, useLocation, useMatch } from 'react-router-dom';

export default function BottomTabBar({ role, navLinks }) {
  let publicLinks = navLinks.filter((link) => !link.canAccess);
  let accessableLinks = navLinks.filter((link) => link?.canAccess?.includes(role));
  let NumberOfLinks = publicLinks.length + accessableLinks.length;

  return (
    <HStack {...navbarContainer} gap={NumberOfLinks > 4 ? '12px' : '30px'}>
      {navLinks.map((navItem) => (
        <React.Fragment key={navItem.path}>
          {navItem.canAccess ? (
            <>
              {navItem.canAccess?.includes(role) && (
                <LinkButton key={navItem.path} linkItem={navItem} NumberOfLinks={NumberOfLinks} />
              )}
            </>
          ) : (
            <LinkButton key={navItem.path} linkItem={navItem} NumberOfLinks={NumberOfLinks} />
          )}
        </React.Fragment>
      ))}
    </HStack>
  );
}

BottomTabBar.propTypes = {
  navLinks: PropTypes.array,
  role: PropTypes.string
};

const LinkButton = ({ linkItem, NumberOfLinks }) => {
  let location = useLocation();
  let selected;
  if (linkItem?.paths && linkItem.paths.length > 0) {
    if (location?.pathname && linkItem.paths.some((path) => location.pathname.includes(path))) {
      selected = true;
    } else {
      selected = false;
    }
  } else {
    selected = useMatch(linkItem.path);
  }

  return (
    <Link to={linkItem.path}>
      <VStack {...navItems} color={selected ? 'brand.accent' : 'essentials.black'}>
        <Box boxSize={NumberOfLinks > 4 ? ['20px'] : ['25px']}>
          <linkItem.icon {...navBarIconStyles} />
        </Box>
        <Text
          style={{ margin: 0 }}
          {...navbarTextStyles}
          fontSize={NumberOfLinks > 4 ? ['12px'] : ['14px']}>
          {linkItem.name}
        </Text>
      </VStack>
    </Link>
  );
};

LinkButton.propTypes = {
  linkItem: PropTypes.object,
  NumberOfLinks: PropTypes.number
};

const navbarContainer = {
  w: '100%',
  backgroundColor: 'essentials.white',
  color: 'essentials.black',
  pt: [2],
  pb: [2],
  px: [3],
  justifyContent: 'center',
  wrap: 'wrap'
};

const navItems = {
  gap: ['4px', '6px', '8px'],
  cursor: 'pointer'
};

const navBarIconStyles = {
  size: '100%'
};
const navbarTextStyles = {
  fontWeight: '400'
};
