import { mode } from '@chakra-ui/theme-tools';

const styles = {
  global: (props) => ({
    body: {
      bg: mode('brand.bg', 'gray.800')(props)
    },
    '.preview-card-sub-content': {
      fontSize: 'xs',
      p: {
        fontSize: 'xs'
      }
    }
  })
};

export default styles;
