const createTodayCollectionName = () => {
  const currentDate = new Date();
  const dateFormat = new Intl.DateTimeFormat('en-IN', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });

  return dateFormat.format(currentDate).toString();
};

export default createTodayCollectionName;
