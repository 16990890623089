import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card, CardBody, HStack, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import logout from 'actions/auth/logout';

import { signOutFromApp } from 'services/firebase';

export default function Logout({ close }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const LogOut = () => {
    try {
      dispatch(
        logout({
          navigate
        })
      );
      signOutFromApp();
    } catch (error) {
      console.warn('Error occured in logout!');
    }
  };

  return (
    <>
      <Card variant="courseCard" maxW="500px">
        <CardBody>
          <Box pb={3}>
            <Text as={'h6'} fontSize={'xl'} fontWeight={700}></Text>
            <Text fontSize={'md'} fontWeight={'normal'} mb="30px" mt="8px">
              Are you sure that you want to log out?
            </Text>
            <HStack justify={'flex-end'}>
              <Button
                fontSize={'xs'}
                w={'72px'}
                h="32px"
                variant={'brandSecondary'}
                onClick={close}>
                Cancel
              </Button>
              <Button fontSize={'xs'} w={'72px'} h="32px" variant={'brandPrimary'} onClick={LogOut}>
                Logout
              </Button>
            </HStack>
          </Box>
        </CardBody>
      </Card>
    </>
  );
}

Logout.propTypes = {
  close: PropTypes.func
};
