import React from 'react';
import { Center } from '@chakra-ui/react';

export default function PageLoader() {
  return (
    <Center h="100%" w="100%" minH={400}>
      <div className="page-edu-loader"></div>
    </Center>
  );
}
