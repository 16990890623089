import React, { Suspense, Fragment, useRef, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/react';
import ApiErrorIntercept from 'services/ApiErrorIntercept';
import Navbar from 'components/Navbar';
import NavHeader from 'components/NavHeader';
import BottomTabBar from 'components/BottomTabBar';
import { useMediaQuery } from '@chakra-ui/react';

import PageLoader from 'components/PageLoader';
import setPermission from 'actions/auth/setPermission';
import fetchAIPreviousMessages from 'actions/chat/fetchAIPreviousMessages';

import ErrorBoundary from 'ErrorBoundary';

import { DashBoardRoutes } from 'routes';

import { useSelector, useDispatch } from 'react-redux';

import { HouseDoor, Book, ChatLeft, Person, People } from 'react-bootstrap-icons';

import createTodayCollectionName from 'utils/createTodayCollectionName';

import { db } from 'services/firebase';
import { onSnapshot, doc } from 'firebase/firestore';

const navLinks = [
  {
    path: '/dashboard',
    name: 'Home',
    icon: HouseDoor
  },

  {
    path: '/teacher-dashboard/batches',
    name: 'Students',
    icon: People,
    paths: ['/teacher-dashboard/batch-students', '/teacher-dashboard/batches'],
    canAccess: ['teacher']
  },
  {
    path: '/admin-dashboard/course-list',
    name: 'Manage',
    icon: People,
    paths: [
      '/admin-dashboard/batch-students',
      '/admin-dashboard/batches',
      '/admin-dashboard/enrolments',
      '/admin-dashboard/course-list'
    ],
    canAccess: ['admin']
  },

  {
    path: '/courses',
    name: 'My Courses',
    icon: Book,
    paths: ['/courses', '/course-player/', '/Course-details/', 'course-enrol/']
  },

  // {
  //   path: '/user-quizzes',
  //   name: 'Quizzes',
  //   icon: QuestionDiamond,
  //   paths: ['/user-quizzes']
  // },
  {
    path: '/messages',
    name: 'Messages',
    icon: ChatLeft,
    paths: ['/messages', '/conversations']
  },
  {
    path: '/profile',
    name: 'Profile',
    icon: Person,
    paths: ['/profile', '/profile-pages']
  }
];

const DashBoardLayout = () => {
  const contentBg = useColorModeValue('brand.bg', 'gray.800');
  const dispatch = useDispatch();
  const firstRenderRef = useRef(true);
  const navigate = useNavigate();
  const [isLargerThanmd] = useMediaQuery('(min-width: 800px)');

  const permissions = useSelector((state) => state.auth.permissions);
  const user = useSelector((state) => state.auth.user);

  const AIChatPreviousMessages = useSelector((state) => state.aiMessages.previousMessages);

  const AIChatMountingState = useSelector((state) => state.aiMessages.mountingState);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      // aet axios toekn interceptors
      ApiErrorIntercept.setupInterceptors(navigate);
      // fetch user permissions
      dispatch(setPermission());
      return;
    }
  }, []);

  useEffect(() => {
    if (user?.id) {
      // Fetch AI Chat previous Messages
      dispatch(fetchAIPreviousMessages({ user: user.id }));
    }
  }, [user?.id]);

  useEffect(() => {
    // Subsciption for getting AI messages
    if (user?.id && AIChatMountingState === 'complete') {
      let previouslyAddedMessages = AIChatPreviousMessages || [];
      const unSubscribeAIMessages = onSnapshot(
        doc(
          db,
          'aiChatMessages',
          `USER_ID_${user.id}`,
          createTodayCollectionName(),
          'UserMessages'
        ),
        (doc) => {
          let todayMessages = doc?.data()?.messages?.length
            ? doc?.data()?.messages.map((message) => {
                return {
                  id: message.id,
                  sender: message.sender,
                  message: {
                    body: message.message.body,
                    type: message.message.type,
                    timestamp: message.message.timestamp.toMillis()
                  }
                };
              })
            : [];

          let userMessages = todayMessages.filter((message) => {
            return message.sender === 'user' && !message.error;
          });

          dispatch({
            type: 'LOAD_AI_ALL_MESSAGES',
            allMessages: previouslyAddedMessages.concat(todayMessages),
            dailyCredit: 10 - userMessages.length
          });
        }
      );

      return () => {
        unSubscribeAIMessages();
      };
    }
  }, [user?.id, AIChatMountingState, AIChatPreviousMessages]);

  return (
    <Flex bg="brand.primary" h={'100vh'}>
      <ErrorBoundary>
        {isLargerThanmd && <Navbar role={permissions?.type || null} navLinks={navLinks} />}
        <Flex
          flex="1"
          flexDirection={{ base: 'column', lg: 'row' }}
          bg={contentBg}
          m={isLargerThanmd ? 15 : 0}
          ml={0}
          overflow="auto"
          borderRadius={isLargerThanmd ? 'default' : 'none'}>
          {!isLargerThanmd && <NavHeader />}

          {permissions && permissions.type ? (
            <Suspense fallback={<PageLoader />}>
              <Routes>
                {DashBoardRoutes.map((route, idx) => {
                  return (
                    route.element && (
                      <Fragment key={idx}>
                        {route.canAcess ? (
                          <>
                            {route.canAcess.includes(permissions.type) && (
                              <>
                                {route.childRoutes ? (
                                  <Route
                                    path={route.path}
                                    name={route.name}
                                    element={<route.element role={permissions.type} />}>
                                    {route.childRoutes.map((childRoute) => (
                                      <Fragment key={`key-${childRoute.path}`}>
                                        {childRoute.canAcess ? (
                                          <>
                                            {childRoute.canAcess.includes(permissions.type) && (
                                              <Route
                                                path={childRoute.path}
                                                name={childRoute.name}
                                                element={
                                                  <childRoute.element role={permissions.type} />
                                                }
                                              />
                                            )}
                                          </>
                                        ) : (
                                          <Route
                                            path={childRoute.path}
                                            name={childRoute.name}
                                            element={<childRoute.element />}
                                          />
                                        )}
                                      </Fragment>
                                    ))}
                                  </Route>
                                ) : (
                                  <Route
                                    path={route.path}
                                    exact={route.exact}
                                    name={route.name}
                                    element={<route.element role={permissions.type} />}
                                  />
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <Fragment>
                            {route.childRoutes ? (
                              <Route
                                path={route.path}
                                name={route.name}
                                element={<route.element />}>
                                {route.childRoutes.map((childRoute) => (
                                  <Fragment key={`key-${childRoute.path}`}>
                                    {childRoute.canAcess ? (
                                      <>
                                        {childRoute.canAcess.includes(permissions.type) && (
                                          <Route
                                            path={childRoute.path}
                                            name={childRoute.name}
                                            element={<childRoute.element />}
                                          />
                                        )}
                                      </>
                                    ) : (
                                      <Route
                                        path={childRoute.path}
                                        name={childRoute.name}
                                        element={<childRoute.element />}
                                      />
                                    )}
                                  </Fragment>
                                ))}
                              </Route>
                            ) : (
                              <Route
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                element={<route.element />}
                              />
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    )
                  );
                })}

                <Route path="/" exact={true} element={<Navigate to="/dashboard" replace />} />
                <Route path="*" element={<Navigate to="404" replace />} />
              </Routes>
            </Suspense>
          ) : (
            <PageLoader />
          )}
          {!isLargerThanmd && <BottomTabBar role={permissions?.type || null} navLinks={navLinks} />}
        </Flex>
      </ErrorBoundary>
    </Flex>
  );
};

export default React.memo(DashBoardLayout);
