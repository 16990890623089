import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools'; // import utility to set light and dark mode props

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys
);

const BrandTabs = definePartsStyle((props) => {
  const { colorScheme: c } = props; // extract colorScheme from component props

  return {
    tab: {
      color: mode(`grey.600`, `essentials.white`)(props),
      paddingInlineStart: 0,
      _selected: {
        color: mode(`essentials.black`, `${c}.300`)(props)
      }
    },
    // tablist: {
    // },

    tabpanel: {
      paddingTop: '40px',
      paddingInlineStart: 0,
      paddingInlineEnd: [0, 4],
      height: ['auto', 'auto', 'auto', 'calc(100vh - 200px)'],
      overflow: ['auto', 'auto', 'auto', 'scroll']
      // '&::-webkit-scrollbar': { display: 'none' }
    }
  };
});

const tabsTheme = defineMultiStyleConfig({
  variants: { BrandTabs }
});

export default tabsTheme;
