import apiService from 'services/apiService';

const setPermission = () => async (dispatch) => {
  dispatch({ type: 'startLoading' });

  const onSuccess = (response) => {
    let user = Object.assign({}, response.data);
    delete user.role;
    dispatch({ type: 'setPermissions', user, permissions: response.data.role });

    return response;
  };

  const onError = (error) => {
    // console.log('An error occurred:', error.response);
    return error;
  };

  try {
    const response = await apiService.get(`/users/me `);
    onSuccess(response);
  } catch (error) {
    onError(error);
  }

  dispatch({ type: 'stopLoading' });
};

export default setPermission;
