const token = localStorage.getItem('edu_wa_session_token');

const initialState = {
  token,
  user: null,
  permissions: null,
  loading: false,
  error: null,
  _next: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'startLoading':
      return {
        ...state,
        loading: true,
        error: null
      };
    case 'stopLoading':
      return {
        ...state,
        loading: false
      };
    case 'setError':
      return {
        ...state,
        error: action.error
      };
    case 'login':
      return {
        ...state,
        token: action.token,
        user: action.user
      };
    case 'setPermissions':
      return {
        ...state,
        user: action.user,
        permissions: action.permissions
      };
    case 'updateUser':
      return {
        ...state,
        user: action.user
      };
    case 'logout':
      return {
        ...state,
        token: null,
        user: null,
        permissions: null,
        error: null
      };
    case 'setNextPath':
      return {
        ...state,
        _next: action.path || null
      };
    case 'clearNextPath':
      return {
        ...state,
        _next: null
      };

    default:
      return state;
  }
};

export default reducer;
