import apiService from 'services/apiService';
import createTodayCollectionName from 'utils/createTodayCollectionName';
import { Timestamp } from 'firebase/firestore';

const fetchAIPreviousMessages = (postParams) => async (dispatch) => {
  dispatch({ type: 'UPDATE_AI_MOUNT_STATE', mountingState: 'loading' });

  const onSuccess = (response) => {
    let prevMessages = [];

    if (response?.data?.messages?.length) {
      response.data.messages.map((message) => {
        let timeinFirestoreTimestamp = new Timestamp(
          message.message.timestamp._seconds,
          message.message.timestamp._nanoseconds
        );

        prevMessages.push({
          id: message.id,
          sender: message.sender,
          message: {
            body: message.message.body,
            type: message.message.type,
            timestamp: timeinFirestoreTimestamp.toMillis()
          }
        });
      });
    }

    dispatch({
      type: 'LOAD_AI_PREVIOUS_MESSAGES',
      previousMessages: prevMessages
    });

    dispatch({ type: 'UPDATE_AI_MOUNT_STATE', mountingState: 'complete' });
    return response;
  };

  const onError = (error) => {
    dispatch({ type: 'UPDATE_AI_MOUNT_STATE', mountingState: 'none' });
    return error;
  };

  try {
    const response = await apiService.post('/ai-chat/messages', {
      userId: postParams.user,
      excludeCollection: createTodayCollectionName()
    });

    onSuccess(response);
  } catch (error) {
    console.log(error);
    onError(error);
  }
};

export default fetchAIPreviousMessages;
