const initialState = {
  quizDetails: { name: null, code: null },
  status: 'closed', // "closed || ongoing || completed"
  code: null,
  questions: [],
  currentQuestionIndex: 0,
  userAnswers: [], //user answers
  result: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIALIZE_QUIZ': {
      return {
        ...state,
        quizDetails: action.quizDetails,
        status: 'ongoing',
        code: action.code,
        questions: action.questions,
        currentQuestionIndex: 0
      };
    }
    case 'GO_TO_NEXT_QUESTION': {
      let nextQuestionIndex = state.currentQuestionIndex + 1;
      return {
        ...state,
        currentQuestionIndex:
          nextQuestionIndex >= state.questions.length
            ? state.questions.length - 1
            : nextQuestionIndex
      };
    }
    case 'GO_TO_PREVIOUS_QUESTION': {
      let previousQuestionIndex = state.currentQuestionIndex - 1;
      return {
        ...state,
        currentQuestionIndex: previousQuestionIndex < 0 ? 0 : previousQuestionIndex
      };
    }
    case 'ADD_ANSWER_FOR_QUESTION': {
      let updatedAnswersState = [...state.userAnswers].filter(
        (userAnswer) => userAnswer.qstnId !== action.qstnId
      );

      return {
        ...state,
        userAnswers: [...updatedAnswersState, { qstnId: action.qstnId, answer: action.answer }]
      };
    }
    case 'UPDATE_RESULT': {
      return {
        ...state,
        status: 'completed',
        result: action.result
      };
    }

    case 'RESET_QUIZ': {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
