import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const brandPrimary = defineStyle({
  background: 'essentials.white',
  color: 'grey.600',
  fontWeight: 'normal',
  borderColor: 'grey.300',
  border: '1px solid',
  borderRadius: 'brandSmall',

  _dark: {
    background: 'gray.800',
    color: 'grey.300'
  }
});

const pill = defineStyle({
  background: 'essentials.white',
  borderRadius: 'brandSmall',
  boxShadow: '0px 0px 5px 5px rgba(0, 0, 0, 0.02)',
  color: 'grey.800',
  border: '1px solid',
  borderColor: 'gray.300',
  _dark: {
    borderColor: 'gray.600',
    background: 'gray.800',
    color: 'grey.800',
    _focus: {
      borderColor: 'schemeAccent.500'
    }
  },
  _focus: {
    borderColor: 'schemeAccent.500',
    color: 'essentials.black'
  }
});

const textArea = defineStyleConfig({
  variants: { brandPrimary, pill }
});

export default textArea;
