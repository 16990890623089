const initialState = {
  rooms: [],
  search: '',
  loading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'search':
      return {
        ...state,
        search: (action.search || '').toLowerCase()
      };

    case 'rooms-start-loading':
      return {
        ...state,
        loading: true
      };
    case 'fetch-rooms':
      return {
        ...state,
        rooms: action.rooms
      };
    case 'rooms-stop-loading':
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
