import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Box, Image, Text, VStack } from '@chakra-ui/react';
import logo from '../assets/brand/ic.png';
import { BoxArrowRight } from 'react-bootstrap-icons';

import { Link, useLocation, useMatch } from 'react-router-dom';

import { Modal, ModalOverlay, ModalContent, useDisclosure } from '@chakra-ui/react';

import Logout from './Logout';

export default function Navbar({ role, navLinks }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <VStack {...navbarContainer}>
        <VStack {...logoContainer}>
          <Link to={'/dashboard'}>
            <Image src={logo} {...logoImg} />
          </Link>
        </VStack>

        <VStack {...navItemsContainer}>
          {navLinks.map((navItem) => (
            <Fragment key={navItem.path}>
              {navItem.canAccess ? (
                <>
                  {navItem.canAccess?.includes(role) && (
                    <LinkButton key={navItem.path} linkItem={navItem} />
                  )}
                </>
              ) : (
                <LinkButton key={navItem.path} linkItem={navItem} />
              )}
            </Fragment>
          ))}
        </VStack>

        <VStack {...navbarBottomContainer}>
          <VStack {...navItems} onClick={onOpen} mt="20px">
            <Box {...navBarIconContainer}>
              <BoxArrowRight {...navBarIconStyles} />
            </Box>
            <Text style={{ margin: 0 }} {...navbarTextStyles}>
              Log Out
            </Text>
          </VStack>
        </VStack>
      </VStack>

      <Modal isOpen={isOpen} onClose={onOpen}>
        <ModalOverlay />
        <ModalContent marginInline={3}>
          <Logout close={onClose} />
        </ModalContent>
      </Modal>
    </>
  );
}

Navbar.propTypes = {
  role: PropTypes.string,
  navLinks: PropTypes.array
};

const LinkButton = ({ linkItem }) => {
  let location = useLocation();
  let selected;
  if (linkItem?.paths && linkItem.paths.length > 0) {
    if (location?.pathname && linkItem.paths.some((path) => location.pathname.includes(path))) {
      selected = true;
    } else {
      selected = false;
    }
  } else {
    selected = useMatch(linkItem.path);
  }

  return (
    <Link to={linkItem.path}>
      <VStack {...navItems} color={selected ? 'brand.accent' : 'essentials.white'}>
        <Box {...navBarIconContainer}>
          <linkItem.icon {...navBarIconStyles} />
        </Box>
        <Text style={{ margin: 0 }} {...navbarTextStyles}>
          {linkItem.name}
        </Text>
      </VStack>
    </Link>
  );
};

LinkButton.propTypes = {
  linkItem: PropTypes.object
};

const navbarContainer = {
  minHeight: '100vh',
  w: ['75px', '100px', '105px'],
  backgroundColor: 'brand.primary',
  color: 'essentials.white',
  pt: [20],
  pb: [5, 5, 10],
  px: ['5px', '5px', '10px'],
  overflowY: 'auto',
  overflowX: 'hidden'
};
const logoContainer = {
  flex: 1,
  marginBottom: ['20px']
};
const logoImg = {
  width: ['30px', '34px', '38px']
};
const navItemsContainer = {
  flex: 2,
  justifyContent: 'space-evenly',
  gap: ['16px']
};
const navItems = {
  justifyContent: 'space-between',
  gap: ['4px', '6px', '8px'],
  cursor: 'pointer'
};
const navBarIconContainer = {
  boxSize: ['30px']
};
const navBarIconStyles = {
  size: '100%'
};
const navbarTextStyles = {
  fontSize: ['12px'],
  fontWeight: '400'
};
const navbarBottomContainer = {
  flex: 2,
  justifyContent: 'flex-end',
  gap: ['20px', '22px', '24px']
};
