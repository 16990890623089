const initialState = {
  room: null,
  loading: false,
  messages: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'load-messages':
      return {
        ...state,
        room: action.room,
        messages: action.messages
      };
    case 'send-message':
      return {
        ...state,
        messages: [...state.messages, action.message]
      };

    default:
      return state;
  }
};

export default reducer;
