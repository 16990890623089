import axiosInstance from './apiService';
import { signOutFromApp } from 'services/firebase';

export default {
  setupInterceptors: (navigate) => {
    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status == 401 || error.response.status == 403) {
          localStorage.removeItem('edu_wa_session_token');
          navigate('/login');
          signOutFromApp();
        }
        if (error.response.status === 500) {
          navigate('/500');
        }
        return Promise.reject(error);
      }
    );
  }
};
