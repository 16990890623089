import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const brandPrimary = defineStyle({
  background: 'brand.primary',
  color: 'essentials.white',
  fontWeight: 'normal',
  // borderRadius: 'default',

  boxShadow: '0px 0px 5px 5px rgba(0, 0, 0, 0.02)',
  fontSize: '18px',
  _dark: {
    background: 'brand.primary',
    color: 'essentials.white'
  },

  _disabled: {
    background: 'brand.primary',
    color: 'essentials.white'
  },
  _hover: {
    _disabled: {
      background: 'brand.primary',
      color: 'essentials.white'
    }
  }
});

const brandSecondary = defineStyle({
  // background: 'essentials.white',
  color: '#072058',
  fontWeight: 'normal',
  // borderRadius: 'default',
  border: '0.8px solid',
  borderColor: '#072058',
  // boxShadow: '0px 0px 5px 5px rgba(0, 0, 0, 0.02)',
  fontSize: '18px',
  _dark: {
    background: 'grey.200',
    color: 'essentials.white'
  },
  _disabled: {
    background: 'grey.200',
    color: 'essentials.white'
  },
  _hover: {
    _disabled: {
      background: 'grey.200',
      color: 'essentials.white'
    }
  }
});

const brandHighlight = defineStyle({
  background: 'brand.light',
  color: 'essentials.white',
  fontWeight: 'normal',
  // borderRadius: 'default',
  boxShadow: '0px 0px 5px 5px rgba(0, 0, 0, 0.02)',
  fontSize: '18px',
  _dark: {
    background: 'brand.light',
    color: 'essentials.white'
  }
});

const brandDisabled = defineStyle({
  background: 'grey.200',
  color: 'essentials.white',
  boxShadow: '0px 0px 5px 5px rgba(0, 0, 0, 0.02)',
  borderRadius: '10px'
});

const brandAccent = defineStyle({
  background: 'brand.accent',
  color: 'essentials.white',
  boxShadow: '0px 0px 5px 5px rgba(0, 0, 0, 0.02)',
  borderRadius: '10px',

  _disabled: {
    background: 'brand.accent',
    color: 'essentials.white'
  },
  _hover: {
    _disabled: {
      background: 'brand.accent',
      color: 'essentials.white'
    }
  }
});

const button = defineStyleConfig({
  variants: { brandPrimary, brandSecondary, brandHighlight, brandDisabled, brandAccent }
});

export default button;
